<template>
  <div class="list-info" id="watermark">
    <top-bar :title="'车辆管理'" :left="true"></top-bar>
    <div class="list-search">
      <div class="searchValue">
        <input v-model="searchValue" placeholder="请输入名字或车牌号/手机号搜索" @keyup.enter="onSearch" type="search" value="搜索" class="search-content" />
        <img class="search-icon" :src="require('@/assets/img/search.png')" />
        <van-icon v-if="searchValue" class="clear-btn" @click="onCancel" name="clear" color="#999" size="16" />
      </div>
      <van-cell class="photograph">
        <template #default>
          <van-uploader :after-read="cardIdIdentified" :capture="'camera'">
            <div>
              <van-icon size="25" color="#BBB" name="photograph"/>
            </div>
          </van-uploader>
        </template>
      </van-cell>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="8">
          <p @click="subAreaShow = !subAreaShow" :style="{color:subAreaShow?'#387FF5':'#666666'}">
            {{subAreaName}}
            <img :src="require(`@/assets/img/${subAreaShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="typeShow=!typeShow" :style="{color:typeShow?'#387FF5':'#666666'}">
            {{typeName}}
            <img :src="require(`@/assets/img/${typeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="colorShow=!colorShow" :style="{color:colorShow?'#387FF5':'#666666'}">
            {{colorName}}
            <img :src="require(`@/assets/img/${colorShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <van-popup v-model="subAreaShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置" show-toolbar :columns="subAreaList" value-key="label" @confirm="subAreaConfirm" @cancel="subAreaCancel"  />
    </van-popup>
    <van-popup v-model="typeShow" position="bottom">
      <van-picker title="请选择" cancel-button-text="重置" show-toolbar :columns="typeList" value-key="label" @confirm="typeConfirm" @cancel="typeCancel"  />
    </van-popup>
    <van-popup v-model="colorShow" position="bottom">
      <van-picker title="请选择" cancel-button-text="重置" show-toolbar :columns="colorList" value-key="label" @confirm="colorConfirm" @cancel="colorCancel"  />
    </van-popup>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
      <p>新增、添加</p>
    </div>
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <div class="totalCount">共有&nbsp;<span>{{totalCount}}</span>&nbsp;条数据</div>
        <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id, item.subarea)">
          <div class="title">
            <div class="title-left">
              {{item.subareaStr}}
            </div>
            <div class="title-right">
              <van-button type="info" size="small" @click.stop="illPark(item.id, item.userId, item.subarea, item.subareaStr)">违停</van-button>
<!--              <div class="illPark" @click.stop="illPark(item.id, item.userId, item.subarea, item.subareaStr)">违停</div>-->
<!--              {{item.typeStr}}-->
<!--                <a  :href="'tel:'+item.mobile" @click.stop=""><img class="icon" :src="require('@/assets/img/phone.png')" alt=""></a>-->
            </div>
          </div>
          <div class="content">
            <van-image :src="item.url||require('@/assets/img/default.png')" class="content-img">
            <template slot="error">
              <img :src="require('@/assets/img/default.png')" alt="" class="content-img">
            </template>
            <template slot="loading">
              <img :src="require('@/assets/img/default.png')" alt="" class="content-img">
            </template>
            </van-image>
<!--            <img v-if="item.type == '2' || item.type == '1'" :src="imgQi" class="content-img" />-->
<!--            <img v-if="item.type == '3'" :src="imgMo" class="content-img" />-->
<!--            <img v-if="item.type == '4' || item.type == '5'" :src="imgDian" class="content-img" />-->
            <div class="content-text">
              <div class="top-text">{{item.userName}}<van-tag type="primary" text-color="#4581F8" color="rgba(78,124,245,0.12)" style="border-radius: 4px;padding: 5px 10px;margin-left: 12px;">{{item.typeStr}}</van-tag></div>
              <div class="center-text">{{item.mobile}}<img :src="require('@/assets/img/mobile.png')" alt="" class="tel" @click.stop="getRealMobile(item.scId)"/></div>
              <div class="bottom-text">
                <span style="color: #999999;">{{item.typeStr}}/{{item.carNumber}}</span>
              </div>
            </div>
            <van-icon name="arrow" color="#666" class="content-icon"/>
          </div>
        </div>
      </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import {getCarMobile} from '@/utils/common'
import { mapMutations } from 'vuex'
export default {
  components :{
    topBar
  },
  data() {
    return {
      subAreaName: '所在小区',
      typeName: '车辆类型',
      colorName: '车牌颜色',
      dataForm: {
        subarea: '', //小区id
        stateType: '', //车辆类型
        color: '' //车辆类型
      },
      loading: false,
      finished: false,
      subAreaShow: false,
      typeShow: false,
      colorShow: false,
      pullLoading: false,
      page: 0,
      limit: 10,
      searchValue: '',
      subAreaList: [],
      typeList: [],
      colorList: [],
      totalCount: 0,
      dataList: [],
      imgQi: getImageStream('files/wx/images/content/qi.png'),
      imgMo: getImageStream('files/wx/images/content/mo.png'),
      imgDian: getImageStream('files/wx/images/content/dian.png'),
      carIcon: getImageStream('files/wx/images/content/carIcon.png')
    };
  },
  methods: {
    ...mapMutations,
    illPark (id, userId, subarea, subareaStr) {
      this.$router.push({path: '/car-illPark', query: {carId: id, userId, subarea, subareaStr}})
    },
    getInfo (id, subarea) {
      this.$router.push({path: '/car-add', query: {id: id, subarea: subarea}})
    },
    getRealMobile(id) {
      getCarMobile(id,function(data) {
        window.location.href = `tel:${data.mobile}`
      })
    },
    cardIdIdentified(e) {
      let formdata = new FormData();
      formdata.append("file", e.file)
      this.$http({
        url: this.$http.adornUrl('/wxapp/home/file/ocr'),
        method: 'post',
        headers: {
          appid: 'wxcd8dbbf3f2ba3b14',
          type: 3
        },
        data: formdata
      }).then(({data}) => {
        if (data.code == 0) {
          this.searchValue = data.data.result
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/devicecar/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          searchValue: this.searchValue,
          type: this.dataForm.stateType,
          orgId: this.dataForm.subarea,
          color: this.dataForm.color
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.pullLoading = false
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    // 获取小区列表
    getSubareaList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaListByAuth'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
            this.subAreaList = data.subAreaList.map(item => {
              delete item.children
              return item
            })
          if(this.subAreaList.length == 1){
            this.dataForm.subarea = this.subAreaList[0].value
            this.subAreaName = this.subAreaList[0].label
          }
            this.getDataList()
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取车辆类型
    getCarType () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual'),
        method: 'get',
        params: this.$http.adornParams({
          code: 'carType'
        },false)
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.typeList = data.virtualDicts
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取车辆类型
    getColorType () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual'),
        method: 'get',
        params: this.$http.adornParams({
          code: 'licencePlateColor'
        },false)
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.colorList = data.virtualDicts
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    onSearch () {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel () {
      this.page = 0
      this.finished = false
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    subAreaConfirm (value) {
      this.page = 0
      this.dataList = []
      this.subAreaName = value.label
      this.dataForm.subarea = value.value
      this.getDataList()
      this.subAreaShow = false
    },
    subAreaCancel () {
      this.page = 0
      this.dataList = []
      this.dataForm.subarea = ''
      this.subAreaName = '所在小区'
      this.subAreaShow = false
      this.getDataList()
    },
    typeConfirm (value) {
      this.page = 0
      this.dataList = []
      this.typeName = value.label
      this.dataForm.stateType = value.value
      this.typeShow = false
      this.getDataList()
    },
    typeCancel () {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.dataForm.stateType = ''
      this.typeName = '车辆类型'
      this.typeShow = false
      this.colorShow = false
      this.getDataList()
    },
    colorConfirm (value) {
      this.page = 0
      this.dataList = []
      this.colorName = value.label
      this.dataForm.color = value.value
      this.colorShow = false
      this.getDataList()
    },
    colorCancel () {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.dataForm.color = ''
      this.colorName = '车牌颜色'
      this.colorShow = false
      this.getDataList()
    },
    goAdd () {
      this.$router.push('/car-add')
    }
  },
  created () {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    // this.getDataList()
    this.getSubareaList()
    this.getCarType()
    this.getColorType()
  }
}
</script>
<style scoped lang="scss">
.list-search {
  display: flex;
  .searchValue {
    width: 85% !important;
  }
  .photograph {
    flex: 1;
    line-height: 64px;
  }
}
.carImg {
  width: 28px;
  height: 25px;
  position: relative;
  bottom: 3px;
  margin-right: 10px;
}
.content-text .top-text {
  span {
    color: #999999;
    font-size: 24px;
  }
}
.title-right {
  line-height: 70px !important;
  .van-button {
    width: 112px;
    height: 60px;
    //padding: 8px 28px;
    background: #4581F8;
    border-radius: 8px;
    border: none;
    color: #fff;
    margin-bottom: 24px;
  }
}
.center-text {
  line-height: 45px;
  margin-top: 14px;
  color: #4581F8;
}
.bottom-text {
  margin-top: 14px !important;
}
.icon {
  width: 40px;
  height: 40px;
  margin-left: 20px;
}
.tel {
  width: 40px;
  height: 40px;
  margin-left: 40px;
  line-height: 90px;
}
</style>
